import { useRequestHeaders } from '#imports'

export default function useLocale() {
  let locale = 'fr-FR'
  if (import.meta.server) {
    const reqLocale =
      useRequestHeaders()['accept-language']?.split(',')[0]
    if (reqLocale) {
      locale = reqLocale
    }
  } else if (import.meta.client) {
    const navLang = navigator.language
    if (navLang) {
      locale = navLang
    }
  }

  return locale
}
